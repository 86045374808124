.filter-list-item {
  padding: .75rem 0 0;

  &_invisible {
    display: none;
  }

  &__inner-wrapper {
    display: none;
    padding: 0 0 0 1.5rem;

    &_visible {
      display: block;
    }
  }
}
