@import 'assets/styles/core/settings';

.icon-button {
  &__image {
    &_type {
      &_description {
        width: 1.44rem;
        height: 1.13rem;
      }

      &_contacts {
        width: 1.19rem;
        height: 1.13rem;
      }

      &_note {
        width: 1.13rem;
        height: 1.13rem;
      }
    }

    &:hover .a {
      fill: $anchor-color-hover;
      stroke: $anchor-color-hover;
    }
  }
}
