@import 'assets/styles/core';

.companies-list {
  & ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__content {
    display: flex;

    @include breakpoint(small only) {
      width: 100%;
    }
  }

  &__list {
    flex-grow: 1;
    width: 80%;
  }

  &__filters {
    width: 100%;
  }

  &__company {
    margin-bottom: $global-margin;
    position: relative;
  }
}
