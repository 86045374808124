@import 'assets/styles/core';

.tab-content {
  padding: 0 0 $global-margin;
  min-height: 6rem;
  width: 100%;

  &_type_note {
    padding: 0;
  }

  &__description,
  &__contacts {
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    height: 100%;
    white-space: pre-line;
  }

  @include breakpoint(small only) {
    padding: 0 0 .7rem;
    min-height: 0;

    &_type_description {
      font-size: rem-calc(13);
      line-height: 1.4;
    }
  }

  @media (max-width: 320px) {
    padding: 0;
    margin: 0 0 .7rem;
  }
}
