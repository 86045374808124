@import 'assets/styles/core/settings';

.drop-down {
  &__select {
    display: flex;
    padding: .875rem 0 0 .875rem;
    font-weight: $global-weight-bold;
    cursor: pointer;
    align-items: center;
  }

  &__arrow {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 10px;
    transition: opacity .3s;
    opacity: .5;
    background: 50% 50% no-repeat url('svg/triangle-inline.svg');

    &_to-bottom {
      transform: rotate(180deg);
    }
  }

  &__options {
    padding-left: 1rem;

    &_hidden {
      display: none;
    }
  }

  &__checkbox-wrapper {
    margin-top: .5rem;
  }

  &__button-wrapper {
    padding: .5rem 0 0 1.5rem;
  }

  &__show-all-button {
    padding: 0;
    outline: none;
    border: none;
    background: none;
    cursor: pointer;
    text-decoration-line: underline;
    text-decoration-style: dashed;
  }
}
