@import 'assets/styles/core/settings';

.extra-filters {
  border-radius: $global-radius;
  background-color: $alice-blue;

  &__line {
    margin: 1rem .5rem;
    border: 1px solid $light-gray;
    background-color: $light-gray;
  }

  &__checkbox {
    margin: .5rem 0 0 .5rem;
  }

  &__reset-button {
    &-wrapper {
      margin-top: 1rem;
      padding: 1rem;
    }
  }
}
