@import 'assets/styles/core';

.catalog-header {
  margin: 0 0 1rem;

  &__controls {
    height: 2rem;

    @include breakpoint(small only) {
      display: flex;
      flex-direction: column;
    }
  }

  &__map {
    display: none;
    overflow: hidden;
    margin: 1rem 0;

    &_visible {
      display: block;
    }
  }
}
