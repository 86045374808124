@import 'assets/styles/core';

.buttons-panel {
  display: flex;
  align-items: center;
  float: right;

  @include hide-for-only(small);

  &__wrapper {
    padding-left: 1.13rem;
    height: 100%;
  }

  &__wrapper:first-child {
    padding-left: 0;
  }
}
