@import 'assets/styles/core/settings';

.sort-button {
  margin: 0;
  padding: 0;
  height: 100%;
  outline: none;
  border: none;
  background: none;
  cursor: pointer;

  &_active {
    font-weight: $global-weight-bold;
  }
}
