@import 'assets/styles/core';

.tabs {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  &__content {
    width: 100%;
    line-height: 1.6;
    font-size: rem-calc(14);
  }

  &__line {
    width: 100%;
    border-top: 1px solid $medium-gray;

    @include hide-for-only(small);
  }

  &__tab {
    display: flex;
    align-items: flex-end;
    box-sizing: border-box;
    height: 2rem;
    cursor: pointer;

    @include hide-for-only(small);

    &_active {
      border-top: 4px solid $tangerine-yellow;
    }
  }

  &__text {
    padding: 0 .75rem;
    color: $light-black;
    text-align: center;
    opacity: 1;

    @include breakpoint(medium only) {
      font-size: rem-calc(15);
    }
  }
}
