@import 'assets/styles/core/settings';

.truncating-container {
  &__more {
    border-bottom: 1px dashed;
    color: $dark-gray;
    cursor: pointer;
    margin-left: .3rem;
    text-decoration: none;
  }
}
