@import 'assets/styles/core/settings';

.section-filter {
  &__header {
    padding: 0 0 .2rem;
    font-weight: $global-weight-bold;
  }

  &__content {
    padding: 0 0 1rem;
  }

  &__button-wrapper {
    padding: 0 1.5rem 1rem;
  }

  &__show-all-button {
    padding: 0;
    outline: none;
    border: none;
    background: none;
    cursor: pointer;
    text-decoration-line: underline;
  }
}
