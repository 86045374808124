@import 'assets/styles/core';

.sort-controls-panel {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  float: left;
  height: 100%;

  a {
    margin-right: 1rem;
  }

  @include breakpoint(small only) {
    flex-wrap: nowrap;
    font-size: rem-calc(13);
  }

  &__item {
    display: inline-block;

    &:first-child {
      padding-right: .5rem;

      @include hide-for-only(small);
    }
  }
}
