@import 'assets/styles/core/settings';

.checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;

  &_invisible {
    display: none;
  }

  &_disabled {
    cursor: default;
  }

  &__label-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;

    &_disabled {
      cursor: default;
    }
  }

  &__input {
    cursor: pointer;

    &[type='checkbox'] {
      margin: 0;
      flex-shrink: 0;
    }

    &_disabled {
      cursor: default;
    }
  }

  &__label {
    margin: 0 0 0 .5rem;
    font-size: rem-calc(13);

    &_underlined {
      text-decoration-line: underline;
      text-decoration-style: dashed;
    }

    &_disabled {
      color: $dark-gray;
      cursor: default;
    }
  }
}
